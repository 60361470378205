body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: Inter; */
  font-family: Poppins;
}